<template>
  <template-onboarding
    ref="template"
    :steps="onboardingSteps"
    :currentStep="currentOnboardingStep"
  >
    <header class="mb-3">
      <h1 class="font-bold text-xl">Instalação</h1>
    </header>
    <div class="space-y-3">
      <p class="text-gray-500 mb-6">
        Precisamos de algumas informações para conectar nosso meio de pagamento ao seu site.
      </p>
      <px-select
        name="platform"
        :value="platform"
        :label="'Plataforma da sua loja virtual'"
        :items="ecommercePlatformOptions"
        :placeholder="'Selecionar'"
        :disabled="noDigitalMarket"
        :icon="icon"
        :iconAlt="'Ícone de uma loja'"
        :disabledIcon="disabledIcon"
        :subIcons="subIcons"
        @select="(setIcon)(platform = $event)"
      />
      <px-checkbox
        v-model="noDigitalMarket"
        @input="resetSelector"
      >
        Não tenho uma loja virtual
      </px-checkbox>
      <px-input
        v-if="(platform === ('VTEX') || platform === 'Nuvemshop' || platform === 'Precode')"
        v-model="checkoutUrl"
        name="checkoutUrl"
        class="pt-2"
        :placeholder="'www.loja.com.br'"
      >
        URL da loja virtual
      </px-input>
      <px-input
        v-if="platform === 'Outra'"
        v-model="shopName"
        name="shopName"
        class="pt-2"
        :placeholder="'Woocommerce'"
      >
        Nome da plataforma
      </px-input>
    </div>
    <footer class="flex justify-end mt-6">
      <px-button
        kind="primary"
        class="w-full md:w-auto"
        @click="handleClick"
      >
        <span>Avançar</span>
        <ChevronRightIcon slot="right" size="20"/>
      </px-button>
    </footer>
    <div>
  </div>
  </template-onboarding>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { ChevronRightIcon } from '@vue-hero-icons/solid';
import { PxSelect, PxCheckbox, PxButton, PxInput } from '@/components/custom';
import TemplateOnboarding from './TemplateOnboarding.vue';

export default {
  name: 'Installation',
  metaInfo: {
    title: 'Instalação',
  },
  components: {
    TemplateOnboarding,
    PxSelect,
    PxCheckbox,
    PxButton,
    PxInput,
    ChevronRightIcon,
  },
  data: () => ({
    currentOnboardingStep: 6,
    noDigitalMarket: false,
    icon: 'shop-gray.svg',
    disabledIcon: 'shop-gray-disabled.svg',
    subIcons: [
      { value: 'Nuvemshop', icon: 'nuvemshop-lettermark.svg' },
      { value: 'VTEX', icon: 'vtex-lettermark.svg' },
      { value: 'Precode', icon: 'precode-lettermark.svg' },
      { value: 'Plataforma própria', icon: 'cogs-gray.svg' },
      { value: 'Outra', icon: 'shop-gray-small.svg' },
    ],
    platform: {},
    checkoutUrl: '',
    shopName: '',
    platforms: {
      Nuvemshop: 'Nuvemshop',
      VTEX: 'VTEX',
      Precode: 'Precode',
      'Plataforma própria': 'Custom',
      Outra: 'Custom',
    },
    platformsType: {
      Nuvemshop: 'NUVEMSHOP',
      VTEX: 'VTEX',
      Precode: 'PRECODE',
      'Plataforma própria': 'OWN_PLATFORM',
      Outra: 'CUSTOM_PLATFORM',
      Without: 'WITHOUT_PLATFORM',
    },
  }),
  computed: {
    ...mapState('auth', ['uuid']),
    ...mapState('onboarding', ['onboardingSteps', 'ecommercePlatformOptions', 'isLoading']),
  },
  methods: {
    ...mapMutations('onboarding', ['setPlatformData']),
    ...mapActions('onboarding', ['saveInstallationInfo', 'setLoading']),
    setIcon(inputReturn) {
      if (inputReturn === 'Nuvemshop') this.icon = 'nuvemshop-lettermark.svg';
      if (inputReturn === 'VTEX') this.icon = 'vtex-lettermark.svg';
      if (inputReturn === 'Precode') this.icon = 'precode-lettermark.svg';
      if (inputReturn === 'Plataforma própria') this.icon = 'cogs-gray.svg';
      if (inputReturn === '' || inputReturn === 'Outra') this.icon = 'shop-gray.svg';
    },
    resetSelector() {
      this.platform = {};
      this.checkoutUrl = '';
      this.shopName = '';
      this.setIcon('');
    },
    getInvalidInputList() {
      const list = [];
      if (!this.noDigitalMarket && typeof this.platform !== 'string') list.push('platform');
      if ((this.platform === 'VTEX' || this.platform === 'Nuvemshop' || this.platform === 'Precode') && !this.checkoutUrl) list.push('checkoutUrl');
      if (this.platform === 'Outra' && !this.shopName) list.push('shopName');
      return list;
    },
    handleClick() {
      const invalidInputList = this.getInvalidInputList();
      if (invalidInputList.length) {
        this.$refs.template.$emit('invalidInput', invalidInputList);
      } else {
        const hasCheckoutUrl = ['VTEX', 'Nuvemshop', 'Precode'];

        this.setPlatformData({
          platform: this.noDigitalMarket ? null : this.platforms[this.platform],
          platformType: this.noDigitalMarket
            ? this.platformsType.Without
            : (this.platformsType[this.platform] || this.platform),
          checkoutUrl: (hasCheckoutUrl.includes(this.platform) ? this.checkoutUrl : null),
          shopName: (this.platform === 'Outra' ? this.shopName : null),
          noDigitalMarket: this.noDigitalMarket,
        });
        this.saveInstallationInfo({ uuid: this.uuid });
      }
    },
  },
  async mounted() {
    this.setLoading({ loading: true });
    this.setLoading({ loading: false });
  },
};
</script>
